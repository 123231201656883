import clsx from 'clsx';
import numeral from 'numeral';
import { Typography, Skeleton } from '@leaf/ui';
import { getCurrencySymbol } from '@/utils/helper';

interface MarketOverviewProps {
  currency: string | null | undefined;
  currentPrice: number | null | undefined;
  high: number | null | undefined;
  loading: boolean;
  low: number | null | undefined;
  marketCap: number | null | undefined;
  percentageChange: number | null | undefined;
  volume: number | null | undefined;
}

type SkeletonBlockProps = {
  hasChange?: boolean;
  title: string;
};

const SkeletonBlock = ({ hasChange, title }: SkeletonBlockProps) => {
  return (
    <div className="flex flex-col gap-1 p-4">
      <Typography className="text-text-grey" variant="body-regular">
        {title}
      </Typography>
      <div className="flex flex-row items-center gap-2">
        <Skeleton
          loading
          height={28}
          variant="rect"
          width={hasChange ? '40%' : '80%'}
        />
        {hasChange && (
          <Skeleton loading height={22} variant="rect" width="20%" />
        )}
      </div>
    </div>
  );
};

const renderLoading = (): JSX.Element => (
  <div className="grid w-full grid-cols-1 divide-y rounded-lg border md:grid-cols-4 md:divide-x md:divide-y-0">
    <SkeletonBlock hasChange title="Current price" />
    <SkeletonBlock title="Volume" />
    <SkeletonBlock title="Market Cap." />
    <SkeletonBlock title="Year low - high" />
  </div>
);

type OverviewBlockProps = {
  change?: number;
  title: string;
  value: string;
};

const OverviewBlock = ({ change, title, value }: OverviewBlockProps) => {
  const renderPercentageChange = (change: number | undefined): JSX.Element => {
    if (change === undefined) {
      return <></>;
    }
    if (change === 0) {
      return (
        <div className="rounded-full bg-gray-100 px-1.5 pb-0.5">
          <Typography className=" text-text-secondary" variant="body-regular">
            {`— ${change?.toFixed(2)}%`}
          </Typography>
        </div>
      );
    }
    return (
      <div
        className={clsx(
          `flex flex-row items-center rounded-full px-1.5 pb-0.5`,
          change > 0 && 'bg-status-green/10',
          change < 0 && 'bg-critical-red-default/10'
        )}
      >
        <span
          className={clsx(
            'mr-1',
            change > 0 && 'text-status-green',
            change < 0 && 'rotate-180 pb-1 text-critical-red-default'
          )}
        >
          <svg
            fill="none"
            height="4"
            viewBox="0 0 7 4"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.600359 4L5.93296 4C5.9857 3.99879 6.03695 3.98235 6.08054 3.95265C6.12414 3.92296 6.15821 3.88128 6.17865 3.83265C6.19908 3.78402 6.20501 3.73051 6.19572 3.67859C6.18643 3.62666 6.16231 3.57854 6.12627 3.54002L3.45997 0.800112C3.43459 0.774714 3.40445 0.754567 3.37129 0.740821C3.33812 0.727075 3.30257 0.72 3.26666 0.72C3.23076 0.72 3.19521 0.727075 3.16204 0.740821C3.12887 0.754567 3.09873 0.774714 3.07335 0.800112L0.407052 3.54002C0.371017 3.57854 0.346898 3.62666 0.337604 3.67859C0.328311 3.73051 0.33424 3.78402 0.354676 3.83265C0.375113 3.88128 0.409184 3.92296 0.452778 3.95265C0.496373 3.98235 0.547628 3.99879 0.600359 4Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <Typography
          className={clsx(
            'text-sm',
            change > 0 && 'text-status-green',
            change < 0 && 'text-critical-red-default'
          )}
          variant="body-regular"
        >
          {`${change.toFixed(2)}%`}
        </Typography>
      </div>
    );
  };
  return (
    <div className="p-4">
      <Typography className="text-text-grey" variant="body-regular">
        {title}
      </Typography>
      <div className="flex flex-row items-center gap-2">
        <Typography
          className="text-2xl font-semibold text-text-secondary"
          variant="body-large"
        >
          {value}
        </Typography>
        {renderPercentageChange(change)}
      </div>
    </div>
  );
};

export const MarketOverview = ({
  currency,
  currentPrice,
  high,
  loading,
  low,
  marketCap,
  percentageChange,
  volume,
}: MarketOverviewProps): JSX.Element | null => {
  if (loading) {
    return renderLoading();
  }

  if (
    percentageChange == null ||
    currentPrice == null ||
    volume == null ||
    marketCap == null
  ) {
    return null;
  }
  return (
    <div className="grid w-full grid-cols-1 divide-y divide-gray-200 rounded-lg border border-gray-200 md:grid-cols-4 md:divide-x md:divide-y-0">
      <OverviewBlock
        change={percentageChange ? percentageChange : 0}
        title="Current price"
        value={
          currentPrice !== 0 && currentPrice !== null
            ? `${getCurrencySymbol(currency ?? '')}${numeral(
                currentPrice
              ).format('0,0.00[0]')} ${currency ?? ''}`
            : '-'
        }
      />
      <OverviewBlock
        title="Volume"
        value={
          volume !== 0 && volume !== null
            ? numeral(volume).format('0,0.00a')
            : '-'
        }
      />
      <OverviewBlock
        title="Market Cap."
        value={
          marketCap !== null && marketCap !== 0
            ? `${getCurrencySymbol(currency ?? '')}${numeral(
                currency === 'GBX' ? marketCap / 100 : marketCap
              ).format('0.00a')}`
            : `-`
        }
      />
      <OverviewBlock
        title="Year low - high"
        value={
          low !== 0 && high !== 0 && low !== null && high !== null
            ? `${getCurrencySymbol(currency ?? '')}${numeral(low).format(
                '0,0.00[0]'
              )} — ${numeral(high).format('0,0.00[0]')} ${currency ?? ''}`
            : '-'
        }
      />
    </div>
  );
};
